@media (max-width: 768px) {
    .nav-menu {
        font-size: 16px; /* Decrease font size in nav menu on tablets and smaller devices */
        padding: 8px 16px; /* Adjust padding */
        flex-wrap: wrap; /* Allow items to wrap to the next line */
        justify-content: center; /* Center the items */
    }

    .nav-menu > div {
        flex-direction: row; /* Ensure items remain in a row */
        flex-wrap: wrap; /* Allow items to wrap */
        justify-content: center; /* Center items */
        width: 100%; /* Ensure full width */
    }

    .nav-menu button, .tz-class, .react-datepicker-wrapper {
        flex: 1 1 auto; /* Allow items to flex and fill space */
        margin: 5px; /* Margin for spacing */
        padding: 6px 8px; /* Adjust padding for smaller buttons */
    }

    .react-datepicker-wrapper {
        margin: 0;
        padding: 0 5px;
    }

    .react-datepicker__input-container input {
        padding: 6px 8px;
        font-size: 16px;
    }

    .nav-menu-items {
        padding-top: 10px;
    }

    .drawer {
        font-size: 14px; /* Decrease font size in drawer on tablets and smaller devices */
        padding: 8px; /* Adjust padding */
        max-width: 100%;
        height: 50%;
        bottom: -40%; /* Position off-screen initially */
        top: auto; /* Override the top position */
        transition: bottom 0.3s ease; /* Transition effect for sliding up */
    }

    .drawer.open {
        bottom: 0;
    }

    .drawer .team-name {
        flex: 2; /* Increase the flex value to make the team names spans larger */
        white-space: normal; /* Allow text to wrap */
        text-align: center;
    }

    .drawer .match-header {
        margin: 0;
        white-space: nowrap;
    }

    .drawer .match-row .match-date {
        white-space: normal;
    }

    .match-header, .match-row, .search-matches {
        font-size: 14px;
        padding: 8px;
    }

    .match-header .team-name, .match-row .team-name {
        flex: 2; /* Increase the flex value to make the team names spans larger */
        white-space: normal; /* Allow text to wrap */
        text-align: center;
    }

    .match-row span {
        display: flex; /* Change display to flex for better alignment */
        justify-content: center; /* Center the content */
        align-items: center; /* Center vertically */
    }

    .match-row .score {
        flex: 1; /* Ensure scores have enough space */
        min-width: 50px; /* Minimum width for scores */
    }

    .accept-button {
        margin-right: 25px !important;
        margin-left: 1px !important;
    }

    .website-policy {
        width: 90%;
    }

    .penalty-score {
        font-size: 12px;
    }

    .search-matches {
        padding: 0;
    }

    .search-matches input {
        font-size: 16px;
    }

    .home-event {
        padding-left: 5%;
    }

    .away-event {
        padding-right: 5%;
    }

    .match-details {
        font-size: 14px;
    }

    .match-star-icon {
        font-size: 14px;
    }

    .round-select {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .nav-menu {
        font-size: 16px; /* Further decrease font size in nav menu on very small devices */
        padding: 6px 12px; /* Adjust padding */
        flex-wrap: wrap; /* Allow items to wrap to the next line */
        justify-content: center; /* Center the items */
    }

    .nav-menu > div {
        flex-direction: row; /* Ensure items remain in a row */
        flex-wrap: wrap; /* Allow items to wrap */
        justify-content: center; /* Center items */
        width: 100%; /* Ensure full width */
    }

    .nav-menu button, .tz-class, .react-datepicker-wrapper {
        flex: 1 1 auto; /* Allow items to flex and fill space */
        margin: 5px; /* Margin for spacing */
        padding: 6px 8px; /* Adjust padding for smaller buttons */
    }

    .react-datepicker-wrapper {
        margin: 0;
        padding: 0 5px;
    }

    .react-datepicker__input-container input {
        padding: 6px 8px;
        font-size: 16px;
    }

    .nav-menu-items {
        padding-top: 10px;
    }

    .drawer {
        font-size: 14px; /* Further decrease font size in drawer on very small devices */
        padding: 6px; /* Adjust padding */
        max-width: 100%;
        height: 50%; /* Set height to 50% */
        bottom: -40%; /* Position off-screen initially */
        top: auto; /* Override the top position */
        transition: bottom 0.3s ease; /* Transition effect for sliding up */
    }

    .drawer.open {
        bottom: 0;
    }

    .drawer .team-name {
        flex: 2; /* Increase the flex value to make the team names spans larger */
        white-space: normal; /* Allow text to wrap */
        text-align: center;
    }

    .drawer .match-header {
        margin: 0;
        white-space: nowrap;
    }

    .drawer .match-row .match-date {
        white-space: normal;
    }

    .match-header, .match-row, .search-matches {
        font-size: 14px;
        padding: 6px;
    }

    .match-header span, .match-row span {
        flex: 1;
        text-align: center;
    }

    .match-header .team-name, .match-row .team-name {
        flex: 2; /* Increase the flex value to make the team names spans larger */
        white-space: normal; /* Allow text to wrap */
        text-align: center;
    }

    .match-row span {
        display: flex; /* Change display to flex for better alignment */
        justify-content: center; /* Center the content */
        align-items: center; /* Center vertically */
    }

    .match-row .score {
        flex: 1; /* Ensure scores have enough space */
        min-width: 50px; /* Minimum width for scores */
    }

    .accept-button {
        margin-right: 25px !important;
        margin-left: 1px !important;
    }

    .website-policy {
        width: 90%;
    }

    .penalty-score {
        font-size: 10px;
    }

    .search-matches {
        padding: 0;
    }

    .search-matches input {
        font-size: 16px;
    }

    .home-event {
        padding-left: 5%;
    }

    .away-event {
        padding-right: 5%;
    }

    .match-details {
        font-size: 14px;
    }

    .match-star-icon {
        font-size: 14px;
    }

    .round-select {
        font-size: 16px;
    }
}