footer {
    background-color: #004467;
    color: white;
    padding: 20px 0;
    text-align: center;
}

.footer-container {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links,
.footer-social {
    margin: 10px 0;
}

.footer-links a,
.footer-social a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.footer-links a:hover,
.footer-social a:hover {
    color: #007bff;
}

@media (max-width: 768px), (max-width: 480px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-links,
    .footer-social {
        flex-direction: column;
        margin: 5px 0;
    }

    .footer-links a,
    .footer-social a {
        margin: 5px 5px;
    }
}
