.drawer {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  width: 650px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.drawer.open {
  right: 0;
}

.drawer .match-list {
  margin: 0;
}

.drawer .match-header {
  margin: 0;
  white-space: nowrap;
}

.drawer .team-name {
  flex: 2;
  white-space: normal;
  text-align: center;
}

.drawer .match-row .match-date {
  white-space: nowrap;
}

.tabs-container {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.tabs {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
}

.tabs button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #555;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  flex: 1;
  white-space: nowrap;
}

.tabs button:hover {
  color: #333;
}

.tabs button.active {
  color: #004467;
}

.tabs button::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #004467;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.tabs button.active::after {
  transform: scaleX(1);
}

.standings-table {
  width: 100%;
  border-collapse: collapse;
}

.standings-group-name {
  text-align: center;
}

.standings-table th,
.standings-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.standings-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.standings-table tr.highlighted {
  background-color: #ffffd0;
}

.round-select {
  margin-bottom: 15px;
  width: 100%;
  z-index: 1001;
}

.drawer-content {
  flex: 1;
  overflow-y: auto;
}