header {
  background-color: #004467;
  padding: 15px 20px;
  color: white;
}

.nav-menu {
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}

.logo-text {
  font-weight: bold;
  font-size: 24px;
  padding: 10px 15px;
  border: 2px solid white;
  border-radius: 6px;
  margin-bottom: 10px;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.nav-menu-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 55px;
}

.menu-section {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-menu button,
.tz-class {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  border-radius: 6px;
}

.nav-menu button:hover,
.tz-class:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-menu button.active {
  background-color: white;
  color: #004467;
}

/* Date picker styles */
.react-datepicker-wrapper {
  width: 130px;
}

.react-datepicker__input-container {
  display: flex;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s ease;
  height: 36px;
  box-sizing: border-box;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

/* Media query for tablet view */
@media (max-width: 1024px) and (min-width: 769px) {
  .nav-menu-items {
    flex-direction: column;
    gap: 15px;
    margin-left: 0;
  }

  .menu-section {
    width: 100%;
    justify-content: center;
  }
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .logo-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
  }

  .logo-text {
    font-size: 20px;
    padding: 8px 12px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }

  .mobile-menu-toggle {
    display: block;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    font-size: 16px;
    border-radius: 6px;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
  }

  .nav-menu-items {
    display: none;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin-left: 0;
    margin-top: 15px;
  }

  .nav-menu-items.mobile-open {
    display: flex;
  }

  .menu-section {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  .nav-menu button,
  .react-datepicker-wrapper,
  .tz-class {
    flex: 1 1 calc(50% - 10px);
    margin: 5px;
    font-size: 14px;
  }

  .date-row {
    flex-wrap: nowrap;
  }

  .date-row .react-datepicker-wrapper {
    flex: 2;
  }

  .menu-section.date-row {
    justify-content: space-between;
    width: 100%;
  }

  .date-nav-button {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .date-nav-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .react-datepicker-wrapper {
    flex: 1;
    margin: 0 8px;
  }

  .react-datepicker__input-container input {
    height: 40px;
  }
}
