.match-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  overflow-y: auto;
}

.match-header, .match-row, .search-matches {
  display: flex;
  width: 100%;
  max-width: 1100px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

.match-header {
  font-weight: bold;
  background: linear-gradient(90deg, #004467, #005f8f);
  color: white;
  border-bottom: 2px solid #005f8f;
  padding: 12px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1px;
  border-radius: 4px;
}

.match-row {
  transition: background-color 0.2s;
  cursor: pointer;
}

.match-row:hover {
  background-color: #f9f9f9;
}

.match-row span {
  display: inline-block;
  text-align: center;
  margin: 0 5px;
}

.match-header span, .match-row span {
  flex: 1;
  text-align: center;
}

.match-header span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.match-row .team-name, .match-header .team-name {
  flex: 2;
  white-space: nowrap;
}

.league-header {
  background-color: #004467;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  margin: 0;
  color: white;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
}

.league-section {
  width: 100%;
  max-width: 1100px;
  margin-bottom: 20px;
}

.flag-icon {
  width: 20px;
  height: auto;
  margin: 0 10px 0 0;
}

.winner {
  color: red;
  font-weight: bold;
}

.draw {
  color: green;
  font-weight: bold;
}

.loser {
  color: #6c757d;
  opacity: 0.8;
}

.ongoing {
  color: #d32f2f;
  font-weight: bold;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% { color: #e57373; }
  50% { color: #d32f2f; }
  100% { color: #e57373; }
}

.ht-status {
  color: #d32f2f;
  font-weight: bold;
}

.canceled {
  background-color: #ffc4c4;
}

.main-score {
  cursor: pointer;
  z-index: 1000;
  flex-direction: column;
}

.penalty-score {
  font-size: 14px;
  margin: 5px 0 0 0;
}

.time-filter {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.time-filter-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  color: #333;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.time-filter-button:hover {
  background-color: #e0e0e0;
}

.time-filter-button.active {
  background-color: #004467;
  color: white;
  border-color: #004467;
}

.next-day-badge {
  background: linear-gradient(90deg, #004467, #005f8f);
  color: white;
  padding: 8px 16px;
  margin: 12px 0;
  border-radius: 4px;
  font-size: 0.9em;
  display: block;
  text-align: center;
  position: relative;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.next-day-badge::before,
.next-day-badge::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 50px;
  height: 1px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
}

.next-day-badge::before {
  left: 20px;
}

.next-day-badge::after {
  right: 20px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
}