.search-matches {
  padding: 0;
}

.search-matches input {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  background: linear-gradient(90deg, #004467, #005f8f);
  color: #ffffff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.search-matches input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
  padding-left: 30px;
}

.search-matches input:not(:focus) {
  padding-left: 30px;
}

.search-matches input:focus::placeholder {
  color: transparent;
}

.search-matches input::placeholder {
  color: #ffffff;
  opacity: 1;
  text-align: center;
}

.search-icon {
  position: absolute;
  transform: translateY(60%) translateX(40%);
  color: #fff;
  font-size: 16px;
  pointer-events: none;
}