.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #004467;
    color: #fff;
    padding: 20px;
    text-align: center;
    z-index: 1000;
}

.cookie-content {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cookie-content p {
    margin: 0;
    flex: 1;
    text-align: left;
    font-size: 14px;
}

.cookie-content a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
    margin: 0 5px;
}

.accept-button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 8px 12px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 14px;
    border-radius: 4px;

}

.accept-button:hover {
    background-color: white;
    color: #004467;
}
