.match-details {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  font-size: 14px;
}

.match-details-content {
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}

.goal-icon, .substitution-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: green;
}

.own-goal, .var-goal-disallowed {
  color: red !important;
}

.yellow-card-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: yellow;
  border-radius: 2px;
  border: 1px solid black;
}

.red-card-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: red;
}

.second-yellow-card-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: orange;
}

.match-details ul {
  list-style-type: none;
  padding: 20px;
  position: relative;
}

.home-event {
  justify-content: flex-end;
  padding-right: calc(50% + 20px);
  text-align: right;
}

.away-event {
  justify-content: flex-start;
  padding-left: calc(50% + 20px);
  text-align: left;
}

/* New styles for tabs and statistics */
.match-details .tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.match-details .tabs button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.match-details .tabs button.active {
  background-color: #004467;
  color: white;
}

/* Updated styles for match statistics */
.match-statistics {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.stats-table {
  width: 100%;
  border-collapse: collapse;
}

.stats-table tr {
  height: 40px;
}

.home-stat, .away-stat {
  width: 80px;
  text-align: center;
  font-weight: bold;
}

.stat-bar-container {
  width: 100%;
  position: relative;
  padding: 5px 0;
}

.stat-type {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #666;
}

.stat-type span {
  margin-left: 4px;
}

.stat-bars {
  display: flex;
  height: 8px;
  width: 100%;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.home-bar {
  background: #2196f3;
  height: 100%;
  transition: width 0.3s ease;
}

.away-bar {
  background: #4caf50;
  height: 100%;
  transition: width 0.3s ease;
}

.stat-icon {
  margin-right: 8px;
  font-size: 14px;
  color: #666;
}

.yellow-card-stat {
  color: #ffd700;
  -webkit-text-stroke: 1px #666;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
}

.red-card-stat {
  color: #ff0000;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.3));
}

/* Updated Event Styles */
.match-details ul {
    list-style-type: none;
    padding: 20px;
    position: relative;
}

/* Timeline line */
.match-details ul::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #ddd;
    transform: translateX(-50%);
}

.match-details ul li {
    margin: 12px 0;
    position: relative;
    display: flex;
    align-items: center;
}

.home-event {
    justify-content: flex-end;
    padding-right: calc(50% + 20px);
    text-align: right;
}

.away-event {
    justify-content: flex-start;
    padding-left: calc(50% + 20px);
    text-align: left;
}

/* Event time badge */
.event-time {
    background: #004467;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    min-width: 28px;
    text-align: center;
}

.home-event .event-time,
.away-event .event-time {
    order: unset;
}

/* Event icons styling */
.goal-icon, .substitution-icon, .yellow-card-icon, .red-card-icon, .second-yellow-card-icon {
    margin: 0 8px;
    font-size: 16px;
    width: 20px;
    text-align: center;
}

.own-goal {
    color: #dc3545 !important;
}

.var-goal-disallowed {
    color: #dc3545 !important;
    opacity: 0.7;
}

.yellow-card-icon {
    margin: 0 8px;
    color: #ffd700;
    font-size: 16px;
    width: 20px;
    text-align: center;
    position: relative;
    border-radius: 2px;
    background: #ffd700;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.red-card-icon {
    margin: 0 8px;
    color: #dc3545;
    font-size: 16px;
    width: 20px;
    text-align: center;
    position: relative;
    border-radius: 2px;
    background: #dc3545;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.second-yellow-card-icon {
    margin: 0 8px;
    font-size: 16px;
    width: 20px;
    text-align: center;
    position: relative;
    border-radius: 2px;
    background: #ffd700;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.second-yellow-card-icon::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -4px;
    width: 20px;
    height: 22px;
    background: #ffd700;
    border-radius: 2px;
    z-index: -1;
    transform: rotate(-15deg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Event text styling */
.event-text {
    font-size: 14px;
    color: #333;
    flex: 0 1 200px;
}

.home-event .event-text {
    text-align: right;
    margin-right: 8px;
}

.away-event .event-text {
    text-align: left;
    margin-left: 8px;
}

/* Event container hover effect */
.match-details ul li {
    transition: transform 0.2s ease;
}

.match-details ul li:hover {
    transform: scale(1.02);
    z-index: 1;
}

/* Event dot on timeline */
.home-event::after,
.away-event::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border: 2px solid #004467;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
}

/* Substitution specific styling */
.substitution-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.substitution-player {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.home-event .substitution-player {
    justify-content: flex-end;
}

.away-event .substitution-player {
    justify-content: flex-start;
}

.player-in {
    color: #28a745;
}

.player-out {
    color: #dc3545;
}

/* Remove FontAwesome icon's default appearance */
.yellow-card-icon svg,
.second-yellow-card-icon svg,
.red-card-icon svg {
    display: none;
}

.var-goal-disallowed {
    margin: 0 8px;
    color: #dc3545;
    opacity: 0.7;
    font-size: 16px;
    width: 20px;
    text-align: center;
    position: relative;
}

.var-goal-disallowed::after {
    content: '✕';
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
    color: #dc3545;
    font-size: 20px;
    font-weight: bold;
}

/* Clickable score styles */
.clickable-score {
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    padding: 2px 6px;
    border-radius: 4px;
}

.clickable-score:hover {
    background-color: rgba(0, 68, 103, 0.1);
    transform: scale(1.05);
}

.clickable-score::after {
    content: '↗';
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: 12px;
    color: #004467;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.clickable-score:hover::after {
    opacity: 1;
}

/* Add focus styles for accessibility */
.clickable-score:focus {
    outline: 2px solid #004467;
    outline-offset: 2px;
}

/* Optional: Add pulsing animation on hover */
@keyframes score-pulse {
    0% { box-shadow: 0 0 0 0 rgba(0, 68, 103, 0.4); }
    70% { box-shadow: 0 0 0 6px rgba(0, 68, 103, 0); }
    100% { box-shadow: 0 0 0 0 rgba(0, 68, 103, 0); }
}

.clickable-score:hover {
    animation: score-pulse 1.5s infinite;
}

/* Mobile-specific styles for match row layout */
@media (max-width: 768px) {
    .match-row {
        display: grid;
        grid-template-columns: 60px minmax(70px, 1fr) auto minmax(70px, 1fr) 50px 50px;
        gap: 4px;
        align-items: center;
        padding: 4px;
    }

    .team-name {
        max-width: 90px;
        line-height: 1.2;
        min-height: 2.4em;
        display: flex;
        align-items: center;
        word-break: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    .home-team {
        text-align: right;
        justify-content: flex-end;
    }

    .away-team {
        text-align: left;
        justify-content: flex-start;
    }

    .main-score {
        min-width: 40px;
        text-align: center;
        padding: 0 5px;
        align-self: center;
        justify-self: center;
        white-space: nowrap;
    }
}

.match-time-container {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.match-time-container:hover {
    background-color: rgba(0, 68, 103, 0.05);
}

.match-time-container:active {
    background-color: rgba(0, 68, 103, 0.1);
}

/* Remove the opacity changes for star icon */
.match-time-container .match-star-icon {
    opacity: 1; /* Keep star fully visible */
    margin-right: 4px;
}

/* Remove hover opacity change */
.match-time-container:hover .match-star-icon {
    opacity: 1;
}

/* Keep original star visibility */
.match-time-container .match-star-icon svg[data-prefix="fas"],
.match-time-container .match-star-icon svg[data-prefix="far"] {
    opacity: 1;
}
/* Standings Table Styles */
.standings-wrapper {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.standings-group-name {
    color: #004467;
    font-size: 1.2em;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 2px solid #004467;
}

.standings-table-container {
    margin-bottom: 20px;
}

.standings-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 15px;
}

.standings-table thead th {
    background-color: #f8f9fa;
    color: #495057;
    font-weight: 600;
    padding: 12px 8px;
    text-align: center;
    border-bottom: 2px solid #dee2e6;
    white-space: nowrap;
}

.standings-table thead th:first-child {
    border-top-left-radius: 6px;
    text-align: center;
}

.standings-table thead th:last-child {
    border-top-right-radius: 6px;
}

.standings-table tbody tr {
    transition: background-color 0.2s ease;
}

.standings-table tbody tr:hover {
    background-color: rgba(0, 68, 103, 0.05);
}

.standings-table tbody td {
    padding: 10px 8px;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
}

.standings-table tbody td:nth-child(2) {
    text-align: left;
    font-weight: 500;
    min-width: 150px;
}

/* Position column styling */
.standings-table tbody td:first-child {
    font-weight: bold;
    color: #004467;
}

/* Points column styling */
.standings-table tbody td:last-child {
    font-weight: bold;
    background-color: rgba(0, 68, 103, 0.05);
}

/* Highlighted team styling */
.standings-table tbody tr.highlighted {
    background-color: rgba(0, 68, 103, 0.1);
    font-weight: bold;
}

.standings-table tbody tr.highlighted:hover {
    background-color: rgba(0, 68, 103, 0.15);
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    .standings-table {
        font-size: 13px;
    }

    .standings-table thead th,
    .standings-table tbody td {
        padding: 8px 4px;
    }

    .standings-table tbody td:nth-child(2) {
        min-width: 120px;
    }

    /* Optional: Hide less important columns on mobile */
    .standings-table th:nth-child(7),
    .standings-table th:nth-child(8),
    .standings-table td:nth-child(7),
    .standings-table td:nth-child(8) {
        display: none;
    }
}

/* Tooltip styles */
.standings-table th[title] {
    cursor: help;
}

/* Champions League qualification styling example */
.standings-table tbody tr:nth-child(-n+4) td:first-child {
    position: relative;
}

.standings-table tbody tr:nth-child(-n+4) td:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #28a745;
}

/* Relegation zone styling */
.standings-table tbody tr:nth-last-child(-n+3) td:first-child {
    position: relative;
}

.standings-table tbody tr:nth-last-child(-n+3) td:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #dc3545;
}

