.starIcon {
  cursor: pointer;
  font-size: 20px;
  color: white;
  margin-left: auto;
}

.match-star-icon {
  color: #004467;
  font-size: 16px;
}

.league-actions {
  justify-content: space-between;
  display: flex;
  gap: 5px;
}

.league-header-content {
  display: flex;
  align-items: center;
}

.league-header-content > *:last-child {
  margin-left: auto;
}