.tz-class {
  color: white;
  font-size: 14px;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tz-class:hover, .tz-class:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.tz-class option {
  color: #333;
  background-color: #fff;
  padding: 8px;
}

.tz-class:active {
  background-color: rgba(255, 255, 255, 0.3);
}

.tz-select {
  width: 150px;
}

@media (max-width: 768px) {
  .tz-class {
    width: 100%;
    box-sizing: border-box;
  }
}
